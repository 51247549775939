import React from "react";
import { Link } from "gatsby";
import "./horse-cards.css";
// import Image from "../components/Image";
// import femme from "../pages/images/mares/femmefatale.jpg"

const Mares = ({ name, birth, gender, studbook, color, link }) => (
  <div className="horse-card-inside">
    <div className="horse-info">
      <p><span className="blue">BIRTH YEAR: </span>{birth}</p>
      <p><span className="blue"> GENDER: </span>{gender}</p>
      <p><span className="blue">STUDBOOK: </span>{studbook} </p>
      <p><span className="blue">COLOR: </span>{color} </p>
    </div>
    <div>
     <p className="horse-name">{name}</p>
    </div>
  </div>
);

export default Mares;
